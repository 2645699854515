import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppConfig } from 'app/app-config.module';
import { handleHttpClientError } from 'app/shared/helpers/utils';
import { UserFeedbackModel } from 'app/shared/models/user-feedback.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CurrentEmployee } from '../curriculum/models/employee.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  
  currentEmployee: CurrentEmployee;   //the employee that has logged in
  serviceLines: any[];                //all the service lines from DB
  professionalCategories: any;        //all the professional categories from DB
  events: any[];                      //the events loaded from DB

  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) { }

  /**
   * posts a feedback message/information from the current user
   * @param feedback 
   * @returns 
   */
  postFeedback<T>(feedback: UserFeedbackModel): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'app/feedback';
    return this.http.post<T>(url, feedback).pipe(
      catchError(handleHttpClientError)
    );
  }

  /**
   * @returns events that take place in a time interval close to the present
   */
  getCalendar<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'calendar';
    return this.http.get<T>(url).pipe(
      catchError(handleHttpClientError)
    );
  }

  /**
   * @param profile 
   * @returns the result from calling the API that saves the changes made
   * to the current employee profile
   */
  saveProfile<T>(profile: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'employee/update';
    return this.http.patch<T>(url, profile).pipe(
      catchError(handleHttpClientError)
    );
  }
  
  /**
   * @param userId 
   * @returns the data about the employee that has the userId passed as parameter
   */
  getEmployee<T>(userId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `employee/${userId}`;
    return this.http.get<T>(url).pipe(
        catchError(handleHttpClientError)
    );
  }

  /**
   * @returns all the service lines from the DB
   */
  getServiceLines<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/4`;
    return this.http.get<T>(url).pipe(
      catchError(handleHttpClientError)
    );
  }

  /**
   * @returns all the professional categories from the DB
   */
  getProfessionalCategories<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/5`;
    return this.http.get<T>(url).pipe(
      catchError(handleHttpClientError)
    );
  }

  /**
   * @param formGroup 
   * @param debug 
   * @returns emphasizes, with red coloring, if any required field from fromGroup was left unfilled
   */
  public markFormGroupTouched(formGroup: FormGroup, debug : boolean = false) {
    if(!formGroup) return;
    if(!formGroup.controls) return;
    (<any>Object).values(formGroup.controls).forEach(control => {
      //if(debug) console.log(control);
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  uploadAvatar<T>(userId: string, file: File): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/uploadAvatar?userId=' + userId;

    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    
    return this.http.post<T>(url, formData).pipe(
      catchError(handleHttpClientError)
    );
  }

}
