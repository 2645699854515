<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>
  
  <!-- <img src="assets/images/logos/brighten-logo-red.png" alt="" style="max-width: 150px;" > -->

  <span fxFlex></span>
  <!-- Language Switcher -->
  <button mat-button [matMenuTriggerFor]="menu">
    <span>{{currentLang.name}}</span>
  </button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)" style="text-align: -webkit-center;">
        <span>{{lang.name}}</span>
  </button>
</mat-menu>


  <!-- <egret-search-input-over placeholder="Country (e.g. US)" resultPage="/search">
  </egret-search-input-over> -->

  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <!-- <button
    mat-icon-button
    matTooltip="Notifications"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
  >
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn">3</span>
  </button> -->
  
  <!-- Top left user menu -->
  <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
  >
    <!--<img src="assets/images/face-7.jpg" alt="" />-->
    <img class="topbar-image-border" src={{getAvatar()}} alt="" />

  </button>

  <mat-menu #accountMenu="matMenu">
    <!--<button mat-menu-item [routerLink]="['/profile/overview']">-->
    <button mat-menu-item [routerLink]="['/profile/settings', getUserId()]">
      <mat-icon>account_box</mat-icon>
      <span>{{"Profile" | translate}}</span>
    </button>
    <!--
    <button mat-menu-item [routerLink]="['/profile/settings']">
      <mat-icon>settings</mat-icon>
      <span>{{"Account Settings" | translate}}</span>
    </button>
    -->

    <!-- <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button> -->
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{"Sign Out" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
