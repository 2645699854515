import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'app/app-config.module';
import { handleHttpClientError } from 'app/shared/helpers/utils';
import { ILoginModel } from 'app/shared/models/i-login.model';
import { ISessionInfoModel } from 'app/shared/models/i-session-info.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { LocalStoreService } from '../local-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly SESSION_USER_INFO = "SESSION_USER_INFO";

  public returnUrl: string;
  public sessionInfo: ISessionInfoModel;

  constructor(
    private http: HttpClient, 
    private ls: LocalStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private config: AppConfig
  ) { 
    this.route.queryParams.subscribe(params => this.returnUrl = params['returnUrl'] || '/');
    this.sessionInfo = this.getSessionInfo();
  }

  public login<T>(req: ILoginModel): Observable<T> {
    // const url = this.config.getConfig('apiEndpoint') + 'auth';
    const url = this.config.getConfig('apiEndpoint') + 'auth';
    return this.http.post<T>(url, req).pipe(
      map(userData => {
        //console.log(userData);
        this.setSessionInfo(userData);
        return {} as T;
    }),
      catchError(handleHttpClientError)
    );
  }

  public loginWithToken<T>(token: string): Observable<T>{
    this.setSessionInfo({token: token});
    return this.http.get<T>(this.config.getConfig('apiEndpoint') + `auth/user`).pipe(
      map(userData => {
        this.setSessionInfo(userData);
        return {} as T;
      }),
      catchError(handleHttpClientError)
    );
  }

  public getLogoAndAppName<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'app';
    return this.http.get<T>(url).pipe(
      catchError(handleHttpClientError)
    );
  }

  public getProviders<T>(): Observable<T>{
    return this.http.get<T>(this.config.getConfig('apiEndpoint') + 'account/providers').pipe(
      catchError(handleHttpClientError)
    );
  }

  public logout(){
    this.setSessionInfo(null);
    let args = this.returnUrl ? { queryParams: { returnUrl: this.returnUrl } } : {};
    this.router.navigate(['/sessions/signin'], args);
  }

  private setSessionInfo(sessionInfo){
    this.ls.setItem(this.SESSION_USER_INFO, sessionInfo);
    this.sessionInfo = this.getSessionInfo();
  }

  private getSessionInfo(): ISessionInfoModel{
    return this.ls.getItem(this.SESSION_USER_INFO);
  }

  public isAuthenticated(): boolean{
    const sessionInfo = this.getSessionInfo();
    return !!sessionInfo && !!sessionInfo.token;
  }

  public getToken(): string{
    return this.getSessionInfo()?.token;
  }

  public getCurrentUserId(): string{
    return this.getSessionInfo()?.id;
  }

  public getCurrentUserName(): string{
    return this.getSessionInfo()?.name;
  }

  public hasProfileComplete(): boolean{
    return this.getSessionInfo()?.hasProfileComplete;
  }

  public getAvatarUrl(): string{
    return this.getSessionInfo()?.avatarUrl;
  }

  public setAvatarUrl(avatarUrl: string){
    var sessionInfo = this.getSessionInfo();
    sessionInfo.avatarUrl = avatarUrl;
    this.setSessionInfo(sessionInfo);
  }

  public isInRole(role: string): boolean{
    let userData = this.getSessionInfo();
    if(userData == null) return false;
    if(userData.roles.length == 0) return false;
    for(var i = 0; i < userData.roles.length; i++){
      if(userData.roles[i].toLowerCase() == role.toLowerCase() /*|| userData.roles[i].toLowerCase() == this.SuperUserRoleName.toLowerCase()*/){
        return true;
      }
    }
    return false;
  }

}
