import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles?: string[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
  roles?: string[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  iconMenu: IMenuItem[] = [
    // {
    //   name: 'HOME',
    //   type: 'icon',
    //   tooltip: 'Home',
    //   icon: 'home',
    //   state: 'home'
    // },
    // {
    //   name: 'PROFILE',
    //   type: 'icon',
    //   tooltip: 'Profile',
    //   icon: 'person',
    //   state: 'profile/overview'
    // },
    // {
    //   type: 'separator',
    //   name: 'Main Items'
    // },
    {
      name: 'DASHBOARD',
      type: 'link',
      icon: 'dashboard',
      tooltip: 'Dashboard',
      state: 'dashboard/learning-management',
      roles: null
    },
    {
      name: 'Curriculum Vitae',
      type: 'link',
      icon: 'receipt',
      tooltip: 'Curriculum Vitae',
      state: 'curriculum',
      roles: null
    },
    {
      name: 'Search',
      icon: "search",
      type: 'link',
      tooltip: 'Search',
      state: 'search',
      roles: ['Manager']
    }/*,
    {
      name: 'Lists',
      icon: 'list_alt',
      type: 'link',
      tooltip: 'MyLists',
      state: 'myLists'
    }*/

   
    // {
    //   name: 'SESSIONS',
    //   type: 'dropDown',
    //   tooltip: 'Pages',
    //   icon: 'view_carousel',
    //   state: 'sessions',
    //   sub: [
    //     { name: 'FORGOT', state: 'forgot-password' },
    //     { name: 'LOCKSCREEN', state: 'lockscreen' },
    //     { name: 'NOTFOUND', state: '404' },
    //     { name: 'ERROR', state: 'error' }
    //   ]
    // },
    // {
    //   name: 'OTHERS',
    //   type: 'dropDown',
    //   tooltip: 'Others',
    //   icon: 'blur_on',
    //   state: 'others',
    //   sub: [
    //     { name: 'GALLERY', state: 'gallery' },
    //     { name: 'PRICINGS', state: 'pricing' },
    //     { name: 'USERS', state: 'users' },
    //     { name: 'BLANK', state: 'blank' }
    //   ]
    // },
    // {
    //   name: 'Multi Level',
    //   type: 'dropDown',
    //   tooltip: 'Multi Level',
    //   icon: 'format_align_center',
    //   state: '',
    //   sub: [
    //     {
    //       name: 'Level Two',
    //       type: 'dropDown',
    //       state: 'fake-1',
    //       sub: [
    //         { name: 'Level Three', state: 'fake-2' },
    //         { name: 'Level Three', state: 'fake-3' }
    //       ]
    //     },
    //     { name: 'Level Two', state: 'fake-4' },
    //     { name: 'Level Two', state: 'fake-5' }
    //   ]
    // }
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() {}

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.iconMenu);
  }
}
