import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { AuthService } from "app/shared/services/auth/auth.service";
import { ProfileService } from "app/views/profile/profile.service";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public authService: AuthService,
    public profileService: ProfileService,
  ) {}

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      var menu = [];
      for(let i = 0; i < menuItem.length; i++){
        let canAdd = false;
        if(menuItem[i].roles != null){
          for(let r = 0; r < menuItem[i].roles.length; r++){
            if(this.authService.isInRole(menuItem[i].roles[r]) || this.authService.isInRole('SuperUser')){
              canAdd = true;
              if (menuItem[i].sub && menuItem[i].sub.length > 0){
                menuItem[i].sub = menuItem[i].sub.filter(item => item.roles.some(r => this.authService.isInRole(r)));
              }
              break;
            }
          }
        } else {
          canAdd = true;
        }
        if(canAdd) menu.push(menuItem[i]);
      }

      if (menu.length == 1 && menu[0].sub && menu[0].sub.length > 0){
        menu = menu[0].sub;
      }

      this.menuItems = menu;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === "icon"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }

  getUserName(){
    return this.authService.getCurrentUserName();
  }

  getUserId(){
    return this.authService.getCurrentUserId();
  }

  logout(){
    this.authService.logout();
  }

  getAvatar(): string{
    let avatarUrl = this.authService.getAvatarUrl();
    if (!avatarUrl){
      avatarUrl = "assets/images/person.png";
    }
    return avatarUrl;
  }
}
