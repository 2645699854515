import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "../services/auth/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      //TODO: @DM Review this logic
      if (!this.authService.hasProfileComplete() && state.url !== '/dashboard/learning-management'){
        this.router.navigate(["/dashboard/learning-management"]);
      }
      return true;
    } else {
      this.router.navigate(["/sessions/signin"], {
        queryParams: {
          returnUrl: state.url
        }
      });
      return false;
    }
  }
}
