<div class="sidebar-panel">
    <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
        <div class="sidebar-hold">
            
            <!-- App Logo -->
            <div class="branding">
                <!-- <img src="assets/images/egret.png" alt="" class="app-logo"> -->
                <!-- <mat-icon class="app-logo" style="color: white; margin-top: 10px">flare</mat-icon>
                <span class="app-logo-text">Talent Pop</span>
                
                <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span> -->
                <img src="assets/images/logos/brighten-logo-red.png" alt="" style="max-width: 170px;">
            </div>
        
            <!-- Sidebar user -->
            <div class="app-user">
                <div class="app-user-photo">
                <!--<img src="assets/images/face-7.jpg" class="mat-elevation-z1" alt="">-->
                <img src="{{getAvatar()}}" class="mat-elevation-z1" alt="">
                </div>
                <span class="app-user-name mb-8">
                    {{getUserName()}}
                </span>
                <!-- Small buttons -->
                <div class="app-user-controls">
                    <button 
                    class="text-muted"
                    mat-icon-button 
                    mat-xs-button
                    [routerLink]="['/profile/settings', getUserId()]"
                    >
                        <mat-icon>settings</mat-icon>
                    </button>

                    <!--
                    <button 
                    class="text-muted"
                    mat-icon-button 
                    mat-xs-button
                    [matMenuTriggerFor]="appUserMenu">
                        <mat-icon>settings</mat-icon>
                    </button>
                    -->
                    
                    <button 
                    class="text-muted"
                    mat-icon-button 
                    mat-xs-button
                    matTooltip="{{'Sign Out' | translate}}"
                    routerLink="/sessions/signin">
                        <mat-icon>exit_to_app</mat-icon>
                    </button>
                    <!--
                    <mat-menu #appUserMenu="matMenu">
                        <button mat-menu-item routerLink="/profile/settings">
                            <mat-icon>account_box</mat-icon>
                            <span>{{"Profile" | translate}}</span>
                        </button>
                        <button mat-menu-item (click)="logout()">
                            <mat-icon>exit_to_app</mat-icon>
                            <span>{{"Sign Out" | translate}}</span>
                        </button>
                        </mat-menu>
                        -->
                </div>
            </div>
            <!-- Navigation -->
            <app-sidenav [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
        </div>
    </div>
</div>