import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: '/dashboard/learning-management',
    pathMatch: 'full'
  },
  {
    path: 'sessions',
    component: AuthLayoutComponent,
    loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
    data: { title: 'Session'}
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'DASHBOARD'}
      },
      {
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'PROFILE'}
      },
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'OTHERS'}
      },
      {
        path: 'curriculum',
        loadChildren: () => import('./views/curriculum/curriculum.module').then(m => m.CurriculumModule),
        data: { title: 'Curriculum', breadcrumb: 'Curriculum'}
      },
      {
        path: 'page-layouts',
        loadChildren: () => import('./views/page-layouts/page-layouts.module').then(m => m.PageLayoutsModule)
      },
      {
        path: 'myLists',
        loadChildren: () => import('./views/my-lists/my-lists.module').then(m => m.MyListsModule)
      },
      {
        path: 'search',
        loadChildren: () => import('./views/skill-search/skill-search.module').then(m => m.SkillSearchModule)
      },
      
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

