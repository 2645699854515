import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterService } from "@progress/kendo-angular-grid";
import { distinct, filterBy, FilterDescriptor } from "@progress/kendo-data-query";

/**
 * Component that implements a custom filter for kendo grid filter feature;
 * it only displays filter options based on the distinct values that
 * the column field contains at the moment
 */
@Component({
    selector: 'app-custom-filter',
    templateUrl: './custom-filter.component.html',
    styleUrls: [
      './custom-filter.component.css']
  })

export class CustomFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: any;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: any;
  public showFilter = true;
  private value: any[] = [];

  protected textAccessor = (dataItem: any) => 
    this.isPrimitive ? dataItem : (dataItem ? dataItem[this.textField] : null);
    
  protected valueAccessor = (dataItem: any) =>
    this.isPrimitive ? dataItem : (dataItem ? dataItem[this.valueField] : null);

  ngAfterViewInit(){
    this.currentData = this.data;
    this.currentData.sort();
    this.value = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);
    this.showFilter = typeof this.textAccessor(this.currentData[0]) === "string";
  }

  constructor(){}

  public isItemSelected(item) {
    return this.value.some((x) => x === this.valueAccessor(item));
  }

  public onSelectionChange(item, li) {
    if (this.value.some((x) => x === item)) {
      this.value = this.value.filter((x) => x !== item);
    } else {
      this.value.push(item);
    }

    this.filterService.filter({
      filters: this.value.map((value) => ({
        field: this.field,
        operator: "eq",
        value,
      })),
      logic: "or",
    });
    this.onFocus(li);
  }

  public onInput(e: any) {
    this.currentData = distinct(
      [
      ...this.currentData.filter((dataItem) =>
          this.value.some((val) => val === this.valueAccessor(dataItem))
      ),
      ...filterBy(this.data, {
          operator: "contains",
          field: this.textField,
          value: e.target.value,
      }),
      ],
      this.textField
    );
  }

  public onFocus(li: any): void {
    const ul = li.parentNode;
    const below =
      ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
    const above = li.offsetTop < ul.scrollTop;
    // Scroll to focused checkbox
    if (below || above) {
      ul.scrollTop = li.offsetTop;
    }
  }

}