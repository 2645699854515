<ul>
    <li *ngIf="showFilter" style="padding: 0px;">
        <kendo-textbox class="k-textbox" (input)="onInput($event)" placeholder="Search...">
        </kendo-textbox>
    </li>
    <li #itemElement *ngFor="let item of currentData; let i = index"
        (click)="onSelectionChange(valueAccessor(item), itemElement)"
        [ngClass]="{ 'k-state-selected': isItemSelected(item) }">
    
        <input
            type="checkbox"
            id="chk-{{ valueAccessor(item) }}"
            (focus)="onFocus(itemElement)"
            class="k-checkbox"
            [checked]="isItemSelected(item)"
            kendoCheckBox 
        />
        <label
            class="k-multiselect-checkbox k-checkbox-label"
            for="chk-{{ valueAccessor(item) }}"
        >
            {{ textAccessor(item) }}
      </label>
    </li>
</ul>